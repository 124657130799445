import { isEmpty } from "lodash/fp";
import { Id } from "storefront/lib/Id";
import { LegacyListing } from "storefront/Listing";
import { GrailedAPIHeavyListing } from "storefront/Listing/GrailedAPIHeavyListing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { AlgoliaInstantSearchListing } from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import getProductProperties from "../getProductProperties";

export type RemoveFromFavoritesEvent = {
  object: "remove_from_favorites";
  action: "";
  properties: {
    from: string | null | undefined;
    badges: Array<string>;
    brand: string;
    category: string;
    name: string;
    product_id: Id;
    size: string;
    sold: boolean;
    strata: string;
    supersku_id: Id | null | undefined;
    tags: Array<string> | null;
    value: number;
    hasMeasurements?: boolean;
  };
};

const productRemovedFromFavorites = (
  listing:
    | GrailedAPIHeavyListing
    | LegacyListing
    | AlgoliaInstantSearchListing
    | GrailedAPILightListing
    | AlgoliaListing,
  from: string | null | undefined,
): RemoveFromFavoritesEvent => ({
  object: "remove_from_favorites",
  action: "",
  properties: {
    from,
    ...getProductProperties(listing),
    hasMeasurements:
      "measurements" in listing && !isEmpty(listing.measurements),
  },
});

export default productRemovedFromFavorites;
