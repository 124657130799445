import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAnalytics from "storefront/hooks/useAnalytics";
import { From } from "storefront/Analytics/EventCreators/productAddedToWishlist";
import { User, FollowedListings } from "storefront/User";
import {
  addToFittingRoom,
  removeFromFittingRoom,
} from "storefront/actions/fittingRoomActions";
import { GlobalState } from "storefront/GlobalState";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { AlgoliaInstantSearchListing } from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import { LegacyListing } from "storefront/Listing";

const followedListingsSelector = (state: GlobalState): FollowedListings =>
  state.session.currentUser.followedListings || [];

type ValueAndSetter = [boolean, (user: User) => void];

const useIsFavorited = (
  listing:
    | LegacyListing
    | AlgoliaInstantSearchListing
    | GrailedAPILightListing
    | AlgoliaListing,
  from: From | undefined,
): ValueAndSetter => {
  const dispatch = useDispatch();
  const followedListings = useSelector(followedListingsSelector);
  const { track } = useAnalytics();
  const [isFavorited, setIsFavorited] = useState<boolean>(false);

  useEffect(() => {
    if (followedListings.includes(listing.id)) {
      setIsFavorited(true);
    }
  }, [followedListings, listing.id]);

  const toggleIsFavorited = (currentUser: User) => {
    if (isFavorited) {
      setIsFavorited(false);
      try {
        dispatch(removeFromFittingRoom(currentUser.id, listing, track, from));
      } catch {
        setIsFavorited(true);
      }
    } else {
      setIsFavorited(true);
      try {
        dispatch(addToFittingRoom(currentUser.id, track, listing, from));
      } catch {
        setIsFavorited(false);
      }
    }
  };

  return [isFavorited, toggleIsFavorited];
};

export default useIsFavorited;
