import type { Id } from "../../../../lib/Id";
import { post } from "../../../request";

export type Response = Record<string, never>;

/**
 * @name GrailedAPI.v1.Users.Follows.unfollowUser
 * @param {UserId}
 * @return {Promise<Response>}
 */
const unfollowUser = (userId: Id): Promise<Response> =>
  post(`/api/users/${userId}/unfollow`);

export default unfollowUser;
