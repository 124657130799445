import React from "react";
import type { Icon } from "../../../Icon";

type BellProps = Icon & {
  fill?: string;
  color?: string;
};
const fills = {
  empty:
    "M7.27053 10.2783H10.07C10.3072 10.2783 10.5 10.0855 10.5 9.8483V8.52426C10.5 8.40861 10.454 8.29741 10.374 8.21438L9.46063 7.27585V5.53815C9.46063 3.80489 8.4361 2.31628 6.89856 1.71431C6.74436 1.02042 6.1335 0.5 5.4055 0.5C4.67751 0.5 4.06813 1.02042 3.91393 1.71283C2.37787 2.31628 1.35186 3.80489 1.35186 5.53815V7.27585L0.438528 8.21438C0.35698 8.29741 0.3125 8.40861 0.3125 8.52426V9.8483C0.3125 10.0855 0.505248 10.2783 0.742477 10.2783H3.54058C3.85354 10.9979 4.57079 11.5011 5.40556 11.5011C6.24033 11.5011 6.95757 10.9979 7.27053 10.2783ZM9.49999 8.75015L8.46063 7.68211V5.53815C8.46063 4.20842 7.68083 3.09449 6.53399 2.64549L6.03794 2.45128L5.92238 1.93124C5.86482 1.67225 5.642 1.5 5.4055 1.5C5.16967 1.5 4.9476 1.67164 4.89002 1.93021L4.77444 2.44917L4.27958 2.64358C3.13269 3.09415 2.35186 4.20904 2.35186 5.53815V7.68211L1.3125 8.75015V9.27827H4.19615L4.45761 9.87945C4.61751 10.2471 4.983 10.5011 5.40556 10.5011C5.82811 10.5011 6.1936 10.2471 6.3535 9.87945L6.61496 9.27827H9.49999V8.75015Z",
  full: "M9.14813 6.77585L10.0615 7.71438C10.1415 7.79741 10.1875 7.90861 10.1875 8.02426V9.3483C10.1875 9.58552 9.99474 9.77827 9.75751 9.77827H9.72045H6.95825C6.6454 10.4981 5.92802 11.0015 5.09306 11.0015C4.2581 11.0015 3.54071 10.4981 3.22787 9.77827H2.12616H0.429977C0.192748 9.77827 0 9.58552 0 9.3483V8.02426C0 7.90861 0.0444804 7.79741 0.126028 7.71438L1.03936 6.77585V5.03815C1.03936 3.30489 2.06537 1.81628 3.60143 1.21283C3.75563 0.520421 4.36501 0 5.093 0C5.821 0 6.43186 0.520421 6.58606 1.21431C8.1236 1.81628 9.14813 3.30489 9.14813 5.03815V6.77585Z",
};

const getFill = (
  fillParam: string | null | undefined,
  colorParam: string | null | undefined,
) => {
  const color = colorParam || "black";
  const fill = fillParam || "full";
  return (
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    <path fillRule="evenodd" clipRule="evenodd" d={fills[fill]} fill={color} />
  );
};

const Bell = ({ width, height, className, fill, color }: BellProps) => (
  <svg
    className={className}
    height={height || "11px"}
    width={width || "12px"}
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {getFill(fill, color)}
  </svg>
);

export default Bell;
