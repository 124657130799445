import React, { useState, useRef, useEffect } from "react";
import { forceCheck } from "react-lazyload";
import { camelize } from "camelscore";
import { Id } from "storefront/lib/Id";
import { LegacyListing, AlgoliaListingHit } from "storefront/Listing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { NonEmptyArray } from "storefront/lib/NonEmptyArray";
import toArray from "storefront/lib/NonEmptyArray/toArray";
import { ProductClickedFrom, PageType } from "storefront/Analytics/Event";
import { pageTypeFromMap } from "storefront/Analytics/EventCreators/productAddedToWishlist";
// eslint-disable-next-line import/no-named-as-default
import ListingItem from "storefront/components/ListingItem";
import classNames from "classnames";
import Loading from "storefront/components/Loading";
import { InstantSearchFilters } from "../InstantSearchFilters";
import styles from "./NonEmpty.module.scss";

type Props = {
  hasMore: boolean;
  hits: NonEmptyArray<AlgoliaListingHit>;
  onSold: boolean;
  pageType: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
  refineNext: () => void;
  searchState: InstantSearchFilters;
  algoliaAbTestId: string | null;
  algoliaAbTestVariantId: string | null;
};

export type AlgoliaInstantSearchListing = AlgoliaListingHit & {
  _tag: "AlgoliaInstantSearch";
};

export const isAlgoliaInstantSearchListing = (
  listing:
    | LegacyListing
    | AlgoliaInstantSearchListing
    | GrailedAPILightListing
    | AlgoliaListing,
): listing is AlgoliaInstantSearchListing =>
  // eslint-disable-next-line no-underscore-dangle
  "_tag" in listing && listing._tag === "AlgoliaInstantSearch";

// NOTE: We can continue to improve this type with the actual data that come back from Algolia.
export const hitToAlgoliaInstantSearchListing = (
  hit: AlgoliaListingHit,
): AlgoliaInstantSearchListing => ({
  /* eslint-disable no-underscore-dangle */
  ...camelize(hit),
  _tag: "AlgoliaInstantSearch",
  objectID: hit.objectID,
  __queryID: hit.__queryID,
  __position: hit.__position,
  /* eslint-enable no-underscore-dangle */
});

/**
 * NOTE: Algolia's connectStateResults puts NonEmpty's `OwnProps` into a `props` prop
 * that it gives us with the other provided props in `AlgoliaStateResultsProps<OwnProps>`.
 *
 * NOTE: Algolia's connectStateResults gives us a `searchState` prop that is the undebounced
 * search state. NonEmpty's `searchState` prop, which gets passed down from FiltersInstantSearch,
 * is a debounced search state. We debounce `searchState` to account for the URL UUID updating &
 * analytics events firing. We do not want to introduce disparity in what `searchState` is between
 * different components. We want to continue using NonEmpty's debounced `searchState` prop & ignore
 * the `searchState` that connectStateResults gives us.
 */
const NonEmpty = ({
  hasMore,
  hits,
  onSold,
  pageType,
  pageTypeIdentifier,
  pageTypeName,
  refineNext,
  searchState,
  algoliaAbTestId,
  algoliaAbTestVariantId,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const sentinel = useRef<HTMLSpanElement>(null);

  // force check if lazy loaded images are in viewport
  useEffect(() => {
    forceCheck();
    setIsFetching(false);
  }, [hits]);

  useEffect(() => {
    if (!sentinel.current) return () => {};

    const onSentinelIntersection = (
      entries: Array<IntersectionObserverEntry>,
    ) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasMore) {
          refineNext();
          setIsFetching(true);
        }
      });
    };

    const el = sentinel.current;
    const observer = new IntersectionObserver(onSentinelIntersection);
    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [refineNext, hasMore]);

  // Force LazyLoad to render all elements now in viewport without waiting for scroll
  const onFeedLoad = () => {
    if (isLoading) {
      forceCheck();
      setIsLoading(false);
    }
  };

  const productClickedFrom: ProductClickedFrom = {
    component: "feed",
    pageType,
    pageTypeIdentifier: pageTypeIdentifier || null,
    pageTypeName:
      pageType === "search_feed"
        ? searchState.query || undefined
        : pageTypeName || undefined,
    searchText: searchState.query || undefined,
    sort: searchState.sortBy,
  };
  const productClickedFromKey = JSON.stringify(productClickedFrom);
  const fromForListingItem = onSold
    ? "sold"
    : pageTypeFromMap[pageType] || pageType;

  return (
    <div className="right">
      <div
        className={classNames("feed", {
          "less-than-5-items": hits.length < 5,
          "less-than-4-items": hits.length < 4,
          "less-than-3-items": hits.length < 3,
        })}
        onLoad={onFeedLoad}
      >
        {toArray(hits).map((hit) => (
          <ListingItem
            listing={hitToAlgoliaInstantSearchListing(hit)}
            key={`${productClickedFromKey}/${hit.id}`}
            from={fromForListingItem}
            displayStyle="feed"
            productClickedFrom={productClickedFrom}
            hasTarget
            algoliaIndex={searchState.sortBy}
            algoliaAbTestId={algoliaAbTestId}
            algoliaAbTestVariantId={algoliaAbTestVariantId}
          />
        ))}
        {/* Add three empty items to keep the flex grid aligned */}
        <div className="feed-item empty-item" />
        <div className="feed-item empty-item" />
        <div className="feed-item empty-item" />
        <span ref={sentinel} />
      </div>

      {isFetching ? <Loading className={styles.fetching} message="" /> : null}
    </div>
  );
};

export default NonEmpty;
