import React from "react";
import { Icon } from "storefront/Icon";

type Props = {
  isFull?: boolean;
} & Icon;

const Bell = ({ isFull, className, height = "25", width = "24" }: Props) => {
  return isFull ? (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3047 6.5H13.7047V5.06367H11.3047V6.5ZM14.5012 6.5315H10.5012L7.70117 8.42024V14.9443H6.5V16.1443H18.5V14.9443H17.3012V8.42024L14.5012 6.5315ZM14.2895 18.9363H10.6895V17.4363H14.2895V18.9363Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8047 6.03151V5.06367H13.2047V6.03151H14.0012H14.154L14.2808 6.11699L17.0808 8.00573L17.3012 8.15439V8.42024V14.9443H18V16.1443H6V14.9443H6.70117V8.42024V8.15439L6.92156 8.00573L9.72156 6.11699L9.8483 6.03151H10.0012H10.8047ZM16.3012 14.9443V8.68609L13.8483 7.03151H10.154L7.70117 8.68609V14.9443H16.3012ZM13.7895 18.9363H10.1895V17.4363H13.7895V18.9363Z"
        fill="black"
      />
    </svg>
  );
};

export default Bell;
