import { isEmpty } from "lodash/fp";
import { Id } from "storefront/lib/Id";
import { Listing } from "storefront/Listing";
import { GrailedAPIHeavyListing } from "storefront/Listing/GrailedAPIHeavyListing";
import { PageType } from "storefront/Analytics/Event";
import getProductProperties from "../getProductProperties";

export type From =
  | "article"
  | "internal"
  | "price_recommendation"
  | "matthew_williams"
  | "category"
  | "order_confirmation"
  | "favorites"
  | "marquee"
  | "followed_seller"
  | "my_saved_searches"
  | "for_you"
  | "collection"
  | "designer"
  | "seller_shop"
  | "recently_viewed"
  | "listing"
  | "giveaway"
  | "sold"
  | "similar_listings"
  | "feed_sidebar"
  | "wishlist"
  | "closet"
  | "saved_listings";

// @ts-expect-error ts-migrate(2740) FIXME: Type '{ collections: "collection"; designer: "desi... Remove this comment to see the full error message
export const pageTypeFromMap: Record<PageType, From> = {
  collections: "collection",
  designer: "designer",
  category: "category",
  seller_profile: "seller_shop",
  for_you: "for_you",
  favorites: "favorites",
  search_feed: "feed_sidebar",
  homepage: "marquee",
};

export type ProductAddedToWishlistEvent = {
  object: "product";
  action: "added_to_wishlist";
  properties: {
    badges: Array<string>;
    brand: string;
    category: string;
    name: string;
    product_id: Id;
    size: string;
    sold: boolean;
    strata: string;
    supersku_id: Id | null | undefined;
    tags: Array<string> | null;
    value: number;
    from: string | null | undefined;
    hasMeasurements?: boolean;
    algoliaQueryId?: string | null;
  };
};

const productAddedToWishlist = (
  listing: Listing | GrailedAPIHeavyListing,
  from: From,
): ProductAddedToWishlistEvent => ({
  object: "product",
  action: "added_to_wishlist",
  properties: {
    ...getProductProperties(listing),
    from,
    hasMeasurements: !isEmpty(listing.measurements),
  },
});

export default productAddedToWishlist;
