import React from "react";
import type { ComponentType } from "react";
import useMediaQuery from "storefront/hooks/useMediaQuery";

export type MediaQueryHOCProps = {
  doesMediaQueryMatch: boolean | null | undefined;
};

type AllProps<OwnProps> = OwnProps & MediaQueryHOCProps;

function withMediaQuery<OwnProps>(
  mediaQuery: string,
): (arg0: ComponentType<AllProps<OwnProps>>) => ComponentType<OwnProps> {
  return (WrappedComponent) => (props) => {
    const doesMediaQueryMatch = useMediaQuery(mediaQuery);
    return (
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      <WrappedComponent {...props} doesMediaQueryMatch={doesMediaQueryMatch} />
    );
  };
}

export default withMediaQuery;
