import { LegacyListing } from "storefront/Listing";
import findAlgoliaIndexName from "storefront/Config/PublicConfig/findAlgoliaIndexName";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import {
  AlgoliaInstantSearchListing,
  isAlgoliaInstantSearchListing,
} from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import { PublicConfig } from "storefront/Config/PublicConfig";
import { From } from "./EventCreators/productAddedToWishlist";
import { Tracker } from "./Tracker";
import { ProductClickedFrom } from "./Event";
import getProductProperties from "./getProductProperties";

/**
 * @name Analytics.trackProductClickedFrom
 * @description Track Product Clicked when a user clicks on a listing
 */
const trackProductClickedFrom = (
  listing:
    | LegacyListing
    | AlgoliaInstantSearchListing
    | GrailedAPILightListing
    | AlgoliaListing,
  tracker: Tracker,
  config: PublicConfig | null | undefined,
  from?: From,
  productClickedFrom?: ProductClickedFrom,
  additionalTrack?: () => void,
  algoliaAbTestId?: string | null,
  algoliaAbTestVariantId?: string | null,
) => {
  if (additionalTrack) additionalTrack();

  const defaultAlgoliaIndex = config
    ? findAlgoliaIndexName("listings", "default")(config)
    : null;

  tracker.track({
    object: "product",
    action: "clicked",
    properties: {
      ...productClickedFrom,
      ...getProductProperties(listing),
      from,
      /* eslint-disable no-underscore-dangle */
      pageTypeIdentifier:
        isAlgoliaInstantSearchListing(listing) &&
        productClickedFrom?.pageType === "search_feed"
          ? listing.__queryID || null
          : productClickedFrom?.pageTypeIdentifier || null,
      algoliaQueryId:
        isAlgoliaInstantSearchListing(listing) && listing.__queryID
          ? listing.__queryID
          : null,
      position:
        isAlgoliaInstantSearchListing(listing) && listing.__position
          ? listing.__position
          : null,
      defaultAlgoliaIndex,
      algoliaAbTestId: algoliaAbTestId || null,
      algoliaAbTestVariantId: algoliaAbTestVariantId || null,
    },
  });
};

export default trackProductClickedFrom;
