import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { Id } from "storefront/lib/Id";
import { post } from "storefront/GrailedAPI/request";

type Response = { data: GrailedAPILightListing };

const unpack = (res: Response): GrailedAPILightListing => res.data;

const addListingToDefaultSavedList = (
  listingId: Id,
): Promise<GrailedAPILightListing> =>
  post(`/api/saved_lists/default/listings/${listingId}`).then(unpack);

export default addListingToDefaultSavedList;
