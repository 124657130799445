import React from "react";
import cn from "classnames";
import useIsFavorited from "storefront/hooks/useIsFavorited";
import { From } from "storefront/Analytics/EventCreators/productAddedToWishlist";
import { AlgoliaInstantSearchListing } from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import { LegacyListing } from "storefront/Listing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import authWrapper, {
  WrappedComponentProps,
} from "storefront/components/Authentication/authWrapper";
import Heart from "storefront/components/Icons/Heart";
import styles from "./HeartButton.module.scss";

type OwnProps = {
  className?: string;
  listing:
    | LegacyListing
    | AlgoliaInstantSearchListing
    | GrailedAPILightListing
    | AlgoliaListing;
  from: From | undefined;
  isSimilarListingsItem?: boolean;
};

type Props = WrappedComponentProps<OwnProps>;

const HeartButton = ({
  className,
  listing,
  from,
  authenticated,
  isSimilarListingsItem,
}: Props) => {
  const [isFavorited, toggleIsFavorited] = useIsFavorited(
    listing,
    isSimilarListingsItem ? "similar_listings" : from,
  );

  const onClick = authenticated((user) => toggleIsFavorited(user), {
    modalTrigger: "favorite_listing",
    verifications: new Set(["email"]),
  });

  const title = isFavorited ? "Remove from Favorites" : "Add to Favorites";

  return (
    <button
      type="button"
      className={cn(styles.heartFollow, className)}
      id={`fr${listing.id}`}
      title={title}
      onClick={onClick}
    >
      <Heart isFull={isFavorited} className={styles.heart} />
    </button>
  );
};

export default authWrapper<OwnProps>(HeartButton);
