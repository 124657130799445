import { del } from "storefront/GrailedAPI/request";
import { Id } from "storefront/lib/Id";
import {
  ActualGrailedAPIHeavyListing,
  formatGrailedAPIHeavyListing,
} from "storefront/Listing/formatListing";
import { GrailedAPIHeavyListing } from "storefront/Listing/GrailedAPIHeavyListing";

type Response = {
  data: ActualGrailedAPIHeavyListing;
};

const unpack = (res: Response): GrailedAPIHeavyListing =>
  formatGrailedAPIHeavyListing(res.data);

const unfavoriteListing = (
  userId: Id,
  id: Id,
): Promise<GrailedAPIHeavyListing> =>
  del<Response>(`/api/users/${userId}/favorites/${id}`).then(unpack);

export default unfavoriteListing;
