import type { Id } from "../../../../lib/Id";
import { post } from "../../../request";

export type Response = Record<string, never>;

/**
 * @name GrailedAPI.v1.Users.Follows.followUser
 * @param {UserId}
 * @return {Promise<Response>}
 */
const followUser = (userId: Id): Promise<Response> =>
  post(`/api/users/${userId}/follow`);

export default followUser;
