import styles from "./Status.module.scss";

const statusClassName = (color: string) => {
  switch (color) {
    case "good":
      return styles.success;

    case "warning":
      return styles.warning;

    case "alert":
      return styles.alert;

    case "secondary":
    default:
      return styles.default;
  }
};

export default statusClassName;
