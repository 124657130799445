import { Listing, LegacyListing } from "storefront/Listing";
import { GrailedAPIHeavyListing } from "storefront/Listing/GrailedAPIHeavyListing";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { AlgoliaInstantSearchListing } from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import { Product } from "./Event";
import soldPriceWithShipping from "./soldPriceWithShipping";

/**
 * @name Analytics.getProductProperties
 * @description Returns a formatted object for a product-related event
 */
const getProductProperties = (
  listing:
    | Listing
    | LegacyListing
    | GrailedAPIHeavyListing
    | GrailedAPILightListing
    | AlgoliaInstantSearchListing
    | AlgoliaListing,
): Product => ({
  badges: listing.badges,
  brand: listing.designerNames,
  category: listing.categoryPath,
  department: listing.department,
  name: listing.title,
  // eslint-disable-next-line camelcase
  product_id: listing.id,
  size: listing.category
    ? `${listing.category}.${listing.size}`
    : `${listing.categoryPath.split(".")[0]}.${listing.size}`,
  sold: listing.sold,
  strata: listing.strata,
  // eslint-disable-next-line camelcase
  supersku_id: "productId" in listing ? listing.productId : undefined,
  tags: listing.hashtags?.length ? listing.hashtags : null,
  value: listing.sold ? soldPriceWithShipping(listing) : listing.price,
});

export default getProductProperties;
