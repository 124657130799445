import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { Id } from "storefront/lib/Id";
import { del } from "storefront/GrailedAPI/request";

type Response = { data: GrailedAPILightListing };

const unpack = (res: Response): GrailedAPILightListing => res.data;

const removeListingFromDefaultSavedList = (
  listingId: Id,
): Promise<GrailedAPILightListing> =>
  del(`/api/saved_lists/default/listings/${listingId}`).then(unpack);

export default removeListingFromDefaultSavedList;
