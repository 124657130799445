import React, { useState } from "react";
import type { Id } from "storefront/lib/Id";
import rotateRight from "../../GrailedAPI/v1/Photos/rotateRight";
import rotateLeft from "../../GrailedAPI/v1/Photos/rotateLeft";
import type { GrailedAPIPhoto } from "../../Photo";

type Props = {
  photoId: Id;
  onSuccess: (arg0: GrailedAPIPhoto) => void;
  onError: (arg0: string) => string | null | undefined;
};

const RotateButtons = ({ photoId, onSuccess, onError }: Props) => {
  const RIGHT_ARROW = "⟳";
  const LEFT_ARROW = "⟲";
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleRotateLeft = (evt: React.SyntheticEvent<HTMLElement>): void => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsDisabled(true);
    rotateLeft(photoId)
      .then(onSuccess)
      .then(() => setIsDisabled(false))
      .catch(onError);
  };

  const handleRotateRight = (evt: React.SyntheticEvent<HTMLElement>): void => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsDisabled(true);
    rotateRight(photoId)
      .then(onSuccess)
      .then(() => setIsDisabled(false))
      .catch(onError);
  };

  return (
    <div className="photo-rotate-buttons-container">
      <button
        type="button"
        onClick={handleRotateLeft}
        disabled={isDisabled}
        title="Rotate Left"
      >
        {LEFT_ARROW}
      </button>
      <button
        type="button"
        onClick={handleRotateRight}
        disabled={isDisabled}
        title="Rotate Right"
      >
        {RIGHT_ARROW}
      </button>
    </div>
  );
};

export default RotateButtons;
