/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import cn from "classnames";

import styles from "./Image.module.scss";
import { getLoader } from "./loaders";

export type Fit = "clip" | "crop" | "scale" | "none";

export type Align = React.CSSProperties["objectPosition"];

export type ImageLoader = (
  src: string,
  options: {
    width?: number;
    quality?: number;
    rotate?: number;
  },
) => string;

type BaseImageProps = JSX.IntrinsicElements["img"] & {
  src: string;
  alt: string;
  srcSet?: undefined;
  priority?: boolean;
  align?: React.CSSProperties["objectPosition"];
  fit?: Fit;
  quality?: number;
  rotate?: number;
  loader?: ImageLoader;
  onClick?: never;
};

type FillImageProps = BaseImageProps & {
  fill: true;
  width?: undefined;
  height?: undefined;
  sizes: string;
};

type FixedImageProps = BaseImageProps & {
  fill?: false;
  width: number;
  height: number;
  sizes?: undefined;
};

export type ImageProps = FillImageProps | FixedImageProps;

export const SMALLEST_DEVICE_SIZE = 400;

export const ALLOWED_SIZES = [
  50, 75, 100, 120, 150, 200, 220, 240, 300, 360, 375, 400, 440, 500, 600, 660,
  700, 750, 800, 850, 880, 900, 950, 1000, 1250, 1500, 1600, 1800, 2200, 2600,
  3000, 3500,
];

function removeMediaConditions(input: string) {
  let result = "";
  let depth = 0; // Track depth of parentheses
  // eslint-disable-next-line no-restricted-syntax
  for (const char of input) {
    // eslint-disable-next-line no-plusplus
    if (char === "(") depth++;
    // eslint-disable-next-line no-plusplus
    else if (char === ")") depth--;
    else if (depth === 0) result += char; // Add characters outside of media conditions
  }
  return result;
}

/**
 * The Image component displays an image with various options for loading priority, size, and custom loading logic.
 * It supports both fill and fixed strategies to control how the image should fit within its container.
 */
export default function Image({
  src,
  alt,
  width,
  height,
  fill,
  sizes,
  priority = false,
  loader,
  className,
  fit,
  align,
  rotate,
  quality,
  ...passedImageAttrbibutes
}: ImageProps) {
  // Warnings for development
  if (process.env.NODE_ENV !== "production") {
    /**
     * Encourages the use of alt attributes for images to improve accessibility.
     */
    if (alt === undefined || !alt.length) {
      // eslint-disable-next-line no-console
      console.warn(
        `Image with src "${src}" is missing an alt tag. Add one now to improve accessibility.`,
      );
    }

    /**
     * Warns against using onClick handlers directly on images to avoid accessibility issues.
     */
    if (passedImageAttrbibutes.onClick) {
      // eslint-disable-next-line no-console
      console.warn(
        `Image with src "${src}" has an onClick handler. This is not recommended as it can cause accessibility issues. Consider wrapping the image in a button instead. https://usability.yale.edu/web-accessibility/articles/links#image-links`,
      );
    }

    /**
     * Validates the rotate prop to ensure it has a valid value.
     */
    if (rotate && ![0, 90, 180, 270].includes(rotate)) {
      // eslint-disable-next-line no-console
      console.warn(
        `Image with src "${src}" has an invalid rotation value. The only valid values are 0, 90, 180, and 270.`,
      );
    }
  }

  /**
   * Dynamically generates class names based on the props to apply styling for fill, fit, and alignment.
   */
  const classes = cn(className, {
    [styles.fill]: fill,
    [styles.crop]: fit === "crop",
    [styles.clip]: fit === "clip",
    [styles.scale]: fit === "scale",
    [styles.top]: align === "top",
    [styles.right]: align === "right",
    [styles.bottom]: align === "bottom",
    [styles.left]: align === "left",
    [styles.center]: align === "center",
  });

  /**
   * Chooses a loader function for the image. Uses a custom loader if provided, or defaults to a built-in loader based on the image source.
   */
  let imageLoader = loader;

  if (!imageLoader) {
    imageLoader = getLoader(src);
  }

  const imgProps: JSX.IntrinsicElements["img"] = {
    ...passedImageAttrbibutes,
    className: classes,
  };

  /**
   * Priority props handling
   */
  if (priority) {
    imgProps.loading = "eager";
    imgProps.decoding = "auto";
    imgProps.fetchpriority = "high";
  } else {
    imgProps.loading = "lazy";
    imgProps.decoding = "async";
    imgProps.fetchpriority = "low";
  }

  const baseSrc = imageLoader ? imageLoader(src, { rotate, quality }) : src;

  /**
   * Handling for fill and fixed images to generate appropriate srcSet values
   * The sizes prop is required for fill images and not allowed for fixed images
   * It helps the browser determine which image to load based on the viewport size
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-sizes
   * https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
   * https://web.dev/optimize-cls/#images-without-dimensions
   * https://web.dev/uses-responsive-images/#images-without-dimensions
   * The sizes property can be given in a variety of formats, but the most common is a list of media conditions and lengths.
   * The browser will choose the first matching condition and use the corresponding length.
   * If no conditions are met, the browser will use the default length.
   * ex. sizes="(max-width: 600px) 200px, 50vw"
   * The above attribute specifies that the image is 200 pixels wide when the viewport is 600 pixels wide or smaller, and 50% of the viewport width otherwise.
   * In order to generate a srcset that doesn't contain variations that aren't possible, we need to identify all of the possible sizes that the image could be displayed at.
   * For vw conditions we can take the smallest device size and multiply it by the smallest ratio to get the smallest possible size.
   * For fixed images we can simply take the provided width and find the closest allowed size, as well as the 2x version.
   */
  if (fill) {
    imgProps.sizes = sizes;
    // Remove media conditions from sizes, we only care about the sizes themselves
    const sizesWithoutMediaConditions = removeMediaConditions(sizes);

    // Find all percent sizes
    // i.e. 50vw
    const viewportWidthRe = /(^|\s)(1?\d?\d)vw/g;
    const percentSizes = [];
    for (
      let match;
      // eslint-disable-next-line no-cond-assign
      (match = viewportWidthRe.exec(sizesWithoutMediaConditions));
      match
    ) {
      percentSizes.push(parseInt(match[2], 10));
    }

    // Find the smallest ratio for viewport based sizes
    const smallestRatio = Math.min(...percentSizes) * 0.01;

    // Find all allowed sizes that are larger than the smallest possible size
    const viewportBasedSizes = ALLOWED_SIZES.filter(
      (s) => s >= SMALLEST_DEVICE_SIZE * smallestRatio,
    );

    // Find all fixed sizes
    // i.e. 200px
    const fixedSizeRe = /\)?\b(\d+px)\b/g;
    const fixedSizes = [];
    for (
      let match;
      // eslint-disable-next-line no-cond-assign
      (match = fixedSizeRe.exec(sizesWithoutMediaConditions));
      match
    ) {
      // Find closest allowed size
      const passedSize = parseInt(match[0], 10);
      const closestSize = ALLOWED_SIZES.find((s) => s >= passedSize) || 3500;

      // We also want to provide the 2x so browsers can support high density displays
      // For example sizes="200px" would also want to provide 400px
      const closestSize2x =
        ALLOWED_SIZES.find((s) => s >= passedSize * 2) || 3500;
      fixedSizes.push(closestSize);
      fixedSizes.push(closestSize2x);
    }

    // Combine all sizes and remove duplicates
    const srcsetSizes = Array.from(
      new Set([...viewportBasedSizes, ...fixedSizes].sort((a, b) => a - b)),
    );

    if (imageLoader) {
      imgProps.srcSet = srcsetSizes
        .map(
          (size) =>
            // @ts-ignore - We know imageLoader is defined, not sure why TS is complaining
            `${imageLoader(src, { width: size, rotate, quality })} ${size}w`,
        )
        .join(", ");
    }
  }

  // Fixed images create srcsets based on the provided width with 1x and 2x versions
  if (!fill) {
    if (process.env.NODE_ENV !== "production") {
      // Enforce that width and height are provided for fixed images
      // This should be enforced by TS but we want to provide a more helpful error message
      if (width === undefined || height === undefined) {
        throw new Error(
          `Image with src "${src}" is missing a width or height. Please provide both width and height. https://web.dev/articles/optimize-cls#images-without-dimensions`,
        );
      }
    }
    // These values are what the image will be rendered at so we want the provided ones and not the actual image dimensions
    // For example if <Image width={95} height={95} src="image.jpg" /> is rendered at 95x95 even though the image we request is 100x100
    imgProps.width = width;
    imgProps.height = height;

    // Find the closest allowed size to the provided width
    const baseWidth = ALLOWED_SIZES.find((size) => size >= width) || 3500;

    // We only want to support 2x because any higher density is not necessary
    // https://blog.twitter.com/engineering/en_us/topics/infrastructure/2019/capping-image-fidelity-on-ultra-high-resolution-devices.html
    const doubleWidth =
      ALLOWED_SIZES.find((size) => size >= baseWidth * 2) || 3500;

    if (imageLoader) {
      imgProps.srcSet = `${imageLoader(src, {
        width: baseWidth,
      })} 1x, ${imageLoader(src, { width: doubleWidth, rotate, quality })} 2x`;
    }
  }

  // It's intended to keep `src` the last attribute because React updates
  // attributes in order. If we keep `src` the first one, Safari will
  // immediately start to fetch `src`, before `sizes` and `srcSet` are even
  // updated by React. That causes multiple unnecessary requests if `srcSet`
  // and `sizes` are defined.
  // This bug cannot be reproduced in Chrome or Firefox.
  return <img alt={alt} {...imgProps} src={baseSrc} />;
}
