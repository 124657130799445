import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import {
  muteListing,
  unmuteListing,
} from "storefront/actions/fittingRoomActions";
import {
  GlobalState,
  fittingRoom as fittingRoomSelector,
} from "storefront/GlobalState";
import BellNew from "storefront/components/Icons/Bell/New";
import { LegacyListing } from "storefront/Listing";
import { AlgoliaInstantSearchListing } from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import Bell from "storefront/components/Icons/Bell";
import styles from "./MuteNotifications.module.scss";

const mutedListingIdsSelector = (state: GlobalState): Array<number> =>
  fittingRoomSelector(state).mutedListingIds;

type Props = {
  listing:
    | LegacyListing
    | AlgoliaInstantSearchListing
    | GrailedAPILightListing
    | AlgoliaListing;
  useNewIcon?: boolean;
};

const MuteNotifications = ({ listing, useNewIcon = false }: Props) => {
  const mutedListingIds = useSelector<GlobalState, Array<number>>(
    mutedListingIdsSelector,
  );
  const [isMuted, setIsMuted] = useState<boolean>(
    mutedListingIds.indexOf(Number(listing.id)) !== -1,
  );
  const dispatch = useDispatch();

  const onClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    if (isMuted) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'then' does not exist on type '(dispatch:... Remove this comment to see the full error message
      dispatch(unmuteListing(listing.id)).then(() => {
        setIsMuted(false);
      });
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'then' does not exist on type '(dispatch:... Remove this comment to see the full error message
      dispatch(muteListing(listing.id)).then(() => {
        setIsMuted(true);
      });
    }
  };

  const verb = isMuted ? "Enable" : "Disable";
  const title = `${verb} notifications for this listing`;

  return (
    <button
      type="button"
      className={cn(styles.muteNotifications, { [styles.newIcon]: useNewIcon })}
      id={`mu${listing.id}`}
      title={title}
      onClick={onClick}
    >
      {useNewIcon ? (
        <BellNew isFull={!isMuted} className={styles.bell} />
      ) : (
        <Bell
          fill={isMuted ? "empty" : "full"}
          height="16px"
          width="16px"
          className={styles.bell}
        />
      )}
    </button>
  );
};

export default MuteNotifications;
