import { put } from "../../request";
import type { Id } from "../../../lib/Id";
import type { GrailedAPIPhoto } from "../../../Photo";

type Response = {
  data: GrailedAPIPhoto;
};

const unpack = (res: Response): GrailedAPIPhoto => res.data;

const rotateLeft = (id: Id): Promise<GrailedAPIPhoto> =>
  put<Response>(`/api/photos/${id}/rotate_left`).then(unpack);

export default rotateLeft;
