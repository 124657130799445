import { Id } from "storefront/lib/Id";

interface BuildSimilarListingsUrl {
  listingId: Id;
  filter?: string | null;
  crossStrata?: boolean;
}
const buildSimilarListingsUrl = ({
  listingId,
  filter,
  crossStrata,
}: BuildSimilarListingsUrl): string => {
  if (!filter && !crossStrata) {
    return `/listings/${listingId}/similar`;
  }
  const params = new URLSearchParams({
    ...(filter ? { filter } : {}),
    ...(crossStrata ? { crossStrata: "true" } : {}),
  });

  return `/listings/${listingId}/similar?${params.toString()}`;
};

export default buildSimilarListingsUrl;
