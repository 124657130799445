import React from "react";
import { LegacyListing } from "storefront/Listing";
import {
  AlgoliaInstantSearchListing,
  isAlgoliaInstantSearchListing,
} from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import buildSimilarListingsUrl from "storefront/components/SimilarListings/buildSimilarListingsUrl";
import Link from "storefront/components/Link";
import useAnalytics from "storefront/hooks/useAnalytics";
import ctaTapped from "storefront/Analytics/EventCreators/ctaTapped";
import { ProductClickedFrom } from "storefront/Analytics/Event";
import styles from "./SeeSimilarLink.module.scss";

type Props = {
  listing:
    | LegacyListing
    | AlgoliaInstantSearchListing
    | GrailedAPILightListing
    | AlgoliaListing;
  productClickedFrom: ProductClickedFrom | undefined;
};

const SeeSimilarLink = ({ listing, productClickedFrom }: Props) => {
  const { track } = useAnalytics();

  const trackSeeSimilarClick = () => {
    const pageType = productClickedFrom?.pageType || null;
    const pageTypeName = productClickedFrom?.pageTypeName || null;
    /* eslint-disable no-underscore-dangle */
    const pageTypeIdentifier =
      isAlgoliaInstantSearchListing(listing) && pageType === "search_feed"
        ? listing.__queryID || null
        : productClickedFrom?.pageTypeIdentifier || null;
    /* eslint-enable no-underscore-dangle */
    track(
      ctaTapped("similar_listings", pageType, pageTypeName, pageTypeIdentifier),
    );
  };

  const similarListingsUrl = buildSimilarListingsUrl({
    listingId: listing.id,
  });

  return (
    <Link
      href={similarListingsUrl}
      variant="underline"
      className={styles.link}
      target="_blank"
      onClick={() => trackSeeSimilarClick()}
      rel="nofollow"
    >
      See Similar
    </Link>
  );
};

export default SeeSimilarLink;
