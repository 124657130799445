import type { Id } from "storefront/lib/Id";

export type CTATappedEvent = {
  object: "cta";
  action: "tapped";
  properties: {
    name: string;
    pageType: string | null;
    pageTypeName: string | null;
    pageTypeIdentifier: Id | null;
  };
};

/**
 * @description Creates a "cta tapped" event that can be given to `track` to record the event with our third-party event tracking services.
 *
 */
const ctaTapped = (
  name: string,
  pageType: string | null,
  pageTypeName: string | null,
  pageTypeIdentifier: Id | null,
): CTATappedEvent => ({
  object: "cta",
  action: "tapped",
  properties: {
    name,
    pageType,
    pageTypeName,
    pageTypeIdentifier,
  },
});

export default ctaTapped;
