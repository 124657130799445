import { parseDomain } from "storefront/lib/url";

import { ImageLoader } from ".";
import contentfulImageSrc from "../Image/imageSrc/contentfulImageSrc";

const SUPPORTED_DEFAULT_LOADER_DOMAINS = [
  "media-assets.grailed.com",
  "media.grailed.com",
];

export const defaultLoader: ImageLoader = (src, { width, quality, rotate }) => {
  const params = new URLSearchParams();
  if (width) {
    params.set("w", width.toString());
  }
  if (quality) {
    params.set("q", quality.toString());
  }
  if (rotate) {
    params.set("orient", rotate.toString());
  }
  params.sort();
  return `${src}?${params.toString()}`;
};

export const contentfulLoader: ImageLoader = (src, { width, quality }) => {
  return contentfulImageSrc(src, { width, quality });
};

export const getLoader = (src: string): ImageLoader | undefined => {
  const domain = parseDomain(src);

  if (domain === "images.ctfassets.net") {
    return contentfulLoader;
  }

  if (domain && SUPPORTED_DEFAULT_LOADER_DOMAINS.includes(domain)) {
    return defaultLoader;
  }

  return undefined;
};
