import React from "react";

import { Icon as IconProps } from "storefront/Icon";

type Props = {
  isFull?: boolean;
} & IconProps;

const Bookmark = ({ isFull, ...rest }: Props) => {
  return isFull ? (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={rest.width || 12}
      height={rest.height || 14}
      viewBox="0 0 12 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 0.000305176H11.75V13.9997H10.5603L6 9.63761L1.43966 13.9997H0.25V0.000305176Z"
        fill="black"
      />
    </svg>
  ) : (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={rest.width || 12}
      height={rest.height || 14}
      viewBox="0 0 12 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.352539 0.125H11.6477V13.875H10.4792L6.00011 9.59063L1.521 13.875H0.352539V0.125ZM1.521 1.29346V12.2737L6.00011 7.98929L10.4792 12.2737V1.29346H1.521Z"
        fill="black"
      />
    </svg>
  );
};

export default Bookmark;
